import React, { memo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import { colors, COLOR_CONSTANTS, radius } from 'theme'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import ImageWithFallback from 'components/ImageWithFallback'
import calendar from 'static/svg/calendar.svg'
import globe from 'static/svg/globe.svg'
import map from 'static/svg/map.svg'

const StyledFlex = styled(Flex)`
  background-color: ${colors.white};
  border-radius: ${radius.m};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  box-shadow: 0px 2px 7px rgba(39, 40, 49, 0.05);
  transition: opacity 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
`

const Item = ({ title, description, picture_url, event_start_date, event_type, online, website, city, country }) => {
  return (
    <StyledFlex flexWrap={{ mobile: 'wrap', mobileLarge: 'nowrap' }}>
      <ImageWithFallback
        source={picture_url}
        fallbackSource={map}
        alt={`${event_type} - ${title}`}
        width={{ mobile: '100%', mobileLarge: '200px', tablet: '270px' }}
        maxHeight={{ mobile: '200px', tablet: '300px' }}
        objectFit="cover"
      />
      <Flex flexDirection="column" px="m" py="m">
        <Text color="primary" textAlign="left" fontWeight="bold">
          {`${online ? 'Online' : country.code || ''} | ${event_type}`}
        </Text>
        <Text mt="s" fontSize="l" fontWeight="bold" textAlign="left">
          {title}
        </Text>
        <Text mt="s" textAlign="left">
          {description}
        </Text>
        <Flex py="m">
          <Flex flexWrap="wrap" justifyContent="center">
            <LocalImage source={globe} /> <Text ml="s">{`${online ? 'Online' : city}`}</Text>
          </Flex>
          <Flex ml="m" flexWrap="wrap" justifyContent="center">
            <LocalImage source={calendar} />{' '}
            <Text ml="s">{format(new Date(event_start_date).getTime(), 'dd MMMM yyyy')}</Text>
          </Flex>
        </Flex>
        {website && (
          <Text as="a" fontWeight="bold" color="primary" target="_blank" href={website}>
            View Event Details
          </Text>
        )}
      </Flex>
    </StyledFlex>
  )
}

Item.defaultProps = {
  city: '',
  country: { code: '' },
}

Item.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  picture_url: PropTypes.string.isRequired,
  event_start_date: PropTypes.string.isRequired,
  event_type: PropTypes.string.isRequired,
  online: PropTypes.number.isRequired,
  website: PropTypes.string,
  country: PropTypes.object,
  city: PropTypes.string,
}

export default memo(Item)
