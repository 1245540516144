import React, { memo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import { space, colors, COLOR_CONSTANTS, radius } from 'theme'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import ImageWithFallback from 'components/ImageWithFallback'
import calendar from 'static/svg/calendar.svg'
import globe from 'static/svg/globe.svg'
import map from 'static/svg/map.svg'

const StyledLink = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: initial;
  max-width: 370px;
  width: 100%;
  &:visited {
    color: initial;
  }
  background-color: ${colors.white};
  padding-bottom: ${space.l};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  margin: ${space.l} ${space.m};
  border-radius: ${radius.m};
`

const StyledFlex = styled(Flex)`
  border-bottom: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
`

const Item = ({ title, description, picture_url, event_start_date, event_type, online, website, city, country }) => {
  return (
    <StyledLink flexDirection="column" href={website} target="_blank">
      <ImageWithFallback
        source={picture_url || map}
        fallbackSource={map}
        alt={`${event_type} - ${title}`}
        width="100%"
        height="180px"
        objectFit="unset"
      />
      <StyledFlex py="m" mx="m" justifyContent="space-between">
        <Flex flexWrap="wrap" justifyContent="center">
          <LocalImage source={globe} /> <Text ml="s">{`${online ? 'Online' : city}`}</Text>
        </Flex>
        <Flex flexWrap="wrap" justifyContent="center">
          <LocalImage source={calendar} />{' '}
          <Text ml="s">{format(new Date(event_start_date).getTime(), 'dd MMMM yyyy')}</Text>
        </Flex>
      </StyledFlex>
      <Flex flexDirection="column" mx="m" mt="m">
        <Flex minHeight={{ tablet: '40px' }}>
          <Text color="primary" textAlign="left" fontWeight="bold">
            {`${online ? 'Online' : country.code || ''} | ${event_type}`}
          </Text>
        </Flex>
        <Flex minHeight={{ tablet: '50px' }} mt={{ mobile: 's', tablet: '0' }}>
          <Text fontSize="l" fontWeight="bold" textAlign="left">
            {title}
          </Text>
        </Flex>
        <Text mt="s" textAlign="left">
          {description}
        </Text>
      </Flex>
    </StyledLink>
  )
}

Item.defaultProps = {
  city: '',
  country: { code: '' },
  picture_url: '',
}

Item.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  picture_url: PropTypes.string,
  event_start_date: PropTypes.string.isRequired,
  event_type: PropTypes.string.isRequired,
  online: PropTypes.number.isRequired,
  website: PropTypes.string,
  country: PropTypes.object,
  city: PropTypes.string,
}

export default memo(Item)
