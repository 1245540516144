/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { colors } from 'theme'
import App from 'App'
import SEO from 'components/SEO'
import { Box, Flex } from 'components/Layout'
import { H1, Text } from 'components/Typography'
import Container from 'components/Container'
import CtaHero from 'components/CtaHero'
import Footer from 'components/Footer'
import eventsHero from 'static/images/events_cta_hero.png'
import Item from './Item'
import FeaturedItem from './FeaturedItem'

const Events = ({ pageContext: { featuredEvents, notFeaturedEvents, future } }) => {
  const year = new Date().getFullYear()
  return (
    <App>
      <SEO
        title="Best Marketing Events"
        description={`Explore the best marketing events${
          future ? ` in ${year}` : ''
        }. Digital marketing, social media, mobile marketing, design & development related online and in-person events.`}
        path="/events/"
      />
      <Container maxWidth="1080px">
        <Flex mt="xl" flexDirection="column" alignItems="center" textAlign="center">
          <H1 textAlign="center">Best Marketing Events</H1>
          <Box maxWidth="768px" px="m">
            <Text fontSize="l" mt="m" color="secondaryText" textAlign="center" opacity={0.8}>
              Explore the best marketing events{future ? ` in ${year}` : ''}. Digital marketing, social media, mobile
              marketing, design & development related online and in-person events
            </Text>
          </Box>
        </Flex>
      </Container>
      <Container maxWidth="1400px">
        {featuredEvents.length > 0 && (
          <Box maxWidth="1024px" px="m" mx="auto">
            <Flex mt="l" flexDirection="column" width="100%">
              {featuredEvents.map((item) => (
                <FeaturedItem key={item.id} {...item} />
              ))}
            </Flex>
          </Box>
        )}
        {notFeaturedEvents.length > 0 && (
          <Flex mt="l" justifyContent="center" flexWrap="wrap">
            {notFeaturedEvents.map((item) => (
              <Item key={item.id} {...item} />
            ))}
          </Flex>
        )}
      </Container>
      {future && (
        <Box my="xl" backgroundColor={colors.primary}>
          <Container maxWidth="1080px">
            <CtaHero
              title="Start Planning For Next Year"
              subTitle="Discover trending past events to ensure you don’t miss any networking opportunities"
              link="/events/past"
              buttonText="Past Events"
              img={eventsHero}
            />
          </Container>
        </Box>
      )}
      <Box mt="xxl">
        <Footer />
      </Box>
    </App>
  )
}

Events.defaultProps = {
  pageContext: {
    featuredEvents: [],
    notFeaturedEvents: [],
  },
}

Events.propTypes = {
  pageContext: PropTypes.shape({
    featuredEvents: PropTypes.array,
    notFeaturedEvents: PropTypes.array,
    future: PropTypes.bool.isRequired,
  }),
}

export default Events
