import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Box, Flex } from 'components/Layout'
import { H1, Text } from 'components/Typography'
import Button from 'components/Button'
import searchScreenshot from 'static/images/search_screenshot.png'

const Wrapper = styled(Flex)`
  flex-wrap: wrap;
  position: relative;
`

const StyledImg = styled.img`
  width: 100%;
  max-width: 460px;
  max-height: 320px;
  object-fit: contain;
  pointer-events: none;
`

const ButtonLink = styled(Button.Reversed)`
  display: inline-flex;
  text-decoration: none;
`

const CtaHero = ({ title, subTitle, link, buttonText, img }) => (
  <Wrapper pr={{ mobile: 'xxs', tablet: 'm' }} justifyContent={['center', 'space-between']}>
    <Box my="l" maxWidth="420px">
      <H1 color="white">{title}</H1>
      <Box my="m">
        <Text fontSize="l" color="white" opacity={0.8}>
          {subTitle}
        </Text>
      </Box>
      <ButtonLink as="a" href={link} isInverted>
        <Text fontWeight="medium">{buttonText}</Text>
      </ButtonLink>
    </Box>
    <Flex alignItems="flex-end" height="inherit">
      <StyledImg src={img} />
    </Flex>
  </Wrapper>
)

CtaHero.defaultProps = {
  title: 'Get Started Today',
  subTitle: 'Grow your agency with the #1 marketplace for social media agencies',
  link: '/signup',
  buttonText: 'Get Started',
  img: searchScreenshot,
}

CtaHero.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  link: PropTypes.string,
  buttonText: PropTypes.string,
  img: PropTypes.string,
}

export default CtaHero
